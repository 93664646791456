import { type NextPageContext } from 'next';
import Router from 'next/router';

import { type Maybe } from '@/generated/graphql';

/**
 * Redirect that can be run from server & client for use
 * in Page.getInitialProps
 */
export default function redirect(
  res: Maybe<NextPageContext['res']>,
  route: string | { url: string },
  code = 303
) {
  const target = typeof route === 'string' ? route : route.url;

  if (res) {
    res.writeHead(code, { Location: target }).end();
  } else {
    Router.push(target);
  }
}
