import { useEffect, useRef } from 'react';
import uniqueId from 'lodash/uniqueId';

import { useGlobal } from 'context/Global';

const useScrollLock = (isLocked = true): void => {
  const id = useRef(uniqueId('scroll:'));
  const { lockScroll, unlockScroll } = useGlobal();

  useEffect(() => {
    const scrollId = id.current;
    if (isLocked) lockScroll(scrollId);
    return () => unlockScroll(scrollId);
  }, [id, isLocked, lockScroll, unlockScroll]);
};

export default useScrollLock;
