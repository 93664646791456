import PropTypes from 'prop-types';
import { gql } from '@apollo/client';

import redirect from 'lib/redirect';
import CampaignPage from 'components/campaign-page/CampaignPage';
import DonationFormPage from 'components/donation-form-page/DonationFormPage';
import CampaignPageLayout from 'components/layouts/CampaignPageLayout';
import NotFoundPage from '../../404';

const CampaignPageRoute = ({ campaign }) =>
  campaign.type === 'donation_form' ? (
    <DonationFormPage campaignId={campaign.id} />
  ) : (
    <CampaignPage />
  );

CampaignPageRoute.propTypes = {
  campaign: PropTypes.shape({ id: PropTypes.string.isRequired, type: PropTypes.string.isRequired })
    .isRequired,
};

CampaignPageRoute.getLayout = (page, router, { campaign }) => {
  if (!campaign) return <NotFoundPage />;

  // No layout for donation form campaigns
  if (campaign.type === 'donation_form') return page;

  return (
    <CampaignPageLayout idType="campaign" id={router.query.id} enableCustomTheme>
      {page}
    </CampaignPageLayout>
  );
};

export const QUERY = gql`
  query GetCampaignRoute($id: String!) {
    findCampaigns(id: $id) {
      id
      type
      slug
    }
  }
`;

// Redirect to the vanity URL if it exists
CampaignPageRoute.getInitialProps = async function getInitialProps({
  query,
  apolloClient,
  req,
  res,
}) {
  if (res && req.headers?.['x-amz-cf-id']) {
    // For CloudFront caching
    if (req.cookies?.token) {
      // For users with a token, forbid caching
      res.setHeader('Cache-Control', 'private, no-store, no-cache, must-revalidate');
    } else {
      // For users without a token, allow caching
      res.setHeader('Cache-Control', 'public, s-maxage=60, stale-while-revalidate=59');
    }
  }

  const response = await apolloClient.query({ query: QUERY, variables: { id: query.id } });

  const campaign = response.data?.findCampaigns[0];

  if (!campaign) {
    res.statusCode = 404;
    return {};
  }

  // query.slug will already be set if rendering from the slug route
  if (campaign.slug && !query.slug) redirect(res, { url: `/${campaign.slug}` });
  return { campaign };
};

export default CampaignPageRoute;
